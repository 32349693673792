import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';


import { H1 } from '../PageBuilder/Primitives/Heading';
import PageBuilder, { SectionBuilder } from '../../containers/PageBuilder/PageBuilder';

import FallbackPage, { fallbackSections } from './FallbackPage';
import { ASSET_NAME } from './TermsOfServicePage.duck';
import css from './TermsOfService.module.css';

// This "content-only" component can be used in modals etc.
const TermsOfServiceContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : fallbackSections;

  return (
    <div style={{ textAlign: "justify" }}>
      <H1 as="h2" className={css.title}>
        <FormattedMessage id="TermsOfServicePage.title" />
      </H1>
      <p>Last updated: <b>3rd April 2024</b>.</p>
      <p>Please read these terms of service carefully before using Our Service.</p>
      <ol className={css.mainList}>
        <li>
          <b>Interpretation and Definitions</b>
          <ol className={css.nestedList1}>
            <li>
              <b>Interpretation</b>
              <p>
                The words of which the initial letter is capitalised have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in the singular or in the plural.
              </p>
            </li>
            <li>
              <b>Definitions</b>
              <p>
                For the purposes of these Terms of Service:
              </p>
              <p>
                <b>“Account”</b> means a unique account created for You to access our Service or parts of our Service.
              </p>
              <p>
                <b>“Affiliate”</b> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.
              </p>
              <p>
                <b>“Application”</b> means the software program provided by the Company or Operator accessed by You on any electronic device, named GigExecs.com.
              </p>
              <p>
                <b>“Buyer”</b> refers to users of the Service who are placing Orders for Service.
              </p>
              <p>
                <b>“Country”</b> refers to United States of America, and Delaware where the company is registered.
              </p>
              <p>
                <b>“Company”</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to {" "}
                <a href="www.gigexecs.com" target="_blank">
                  www.GigExecs.com
                </a>{" "}which is the trading name of Perito Ventures LLC, with address 16192 Coastal Highway, in the city of Lewes, County of Sussex, State of Delaware 19958, under our registered agent Harvard Business Services, Inc.
              </p>
              <p>
                <b>“Content”</b> refers to content such as text, images, or other information that can be posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that content.
              </p>
              <p>
                <b>“Clients”</b> refers to blue-chip companies, small and medium-sized enterprises, government institutions, non-profits, startups, or entrepreneurs seeking to hire experienced and senior professionals through our platform.
              </p>
              <p>
                <b>“Device”</b> means any device that can access the Service such as a computer, a cell phone, or a digital tablet.
              </p>
              <p>
                <b>“Feedback”</b> means feedback, innovations, or suggestions sent by You regarding the attributes, performance, or features of our Service..
              </p>
              <p>
                <b>“Good”</b> refers to the items or services offered for sale, or any other means of trading on the Service.
              </p>
              <p>
                <b>“Order”</b> means a request by a Client to purchase or trade by any means Goods on the Application or Website.
              </p>
              <p>
                <b>“Professionals”</b> encompasses highly experienced and senior: freelancers, independent consultants, independent advisors, fractional service providers, and other types of workers offering their services through flexible work models (non full-time employment), with a minimum of 15 years of total professional experience.
              </p>
              <p>
                <b>“Seller”</b> refers to users of the Service who are listing Services and making them available for trade by any means.
              </p>
              <p>
                <b>“Service”</b> refers to the Application or the Website or both.
              </p>
              <p>
                <b>“Terms of Service”</b> (also referred to as "Terms") mean these Terms of Service that form the entire agreement between You and the Company or Operator regarding the use of the Service.
              </p>
              <p>
                <b>“Third-party Social Media Service”</b> means any services or content (including data, information, products, or services) provided by a third party that may be displayed, included, or made available by the Service.
              </p>
              <p>
                <b>“Website”</b> refers to GigExecs, accessible from{" "}
                <a href="www.gigexecs.com" target="_blank">
                  www.gigexecs.com
                </a>.
              </p>
              <p>
                <b>“You”</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>Contact Us</b>
          <p>
            If you have any questions about these Terms of Service, You can contact
            us:
          </p>
          <ul style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: 22 }}>
            <li>By email: <a href='mailto:help@gigexecs.com'>help@gigexecs.com</a></li>
            <li>By visiting our website: <a href="www.gigexecs.com" target="_blank">www.gigexecs.com</a></li>
          </ul>
        </li>
        <li>
          <b>Acknowledgment</b>
          <p>These are the Terms of Service governing the use of this Service and the
            agreement that operates between You and the Company.These Terms of
            Service set out the rights and obligations of all users regarding the use of
            the Service.</p>
          <p>Your access to and use of the Service is conditioned on Your acceptance
            of and compliance with these Terms of Service. These Terms of Service
            apply to all visitors, users, and others who access or use the Service.</p>
          <p>By accessing or using the Service You agree to be bound by these Terms
            of Service. If You disagree with any part of these Terms of Service then
            You may not access the Service.</p>
          <p>You represent that you are over the age of majority according to the laws
            of your country or the Country, whichever is higher. The Company or
            Operator does not permit those under that age to use the Service.</p>
          <p>Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of the Company or
            Operator. Our Privacy Policy describes Our policies and procedures on
            the collection, use, and disclosure of Your personal information when You
            use the Application or Website and tells You about Your privacy rights and
            how the law protects You. Please read Our Privacy Policy carefully before
            using Our Service.</p>
        </li>
        <li>
          <b>Purpose of GigExecs</b>
          <p>The GigExecs platform is as an online on-demand marketplace where
            highly experienced and senior professionals ("Professionals") collaborate
            with "Clients" on project-based tasks, typically completed over short term
            periods instead of full-time employment. At GigExecs, our role is strictly
            facilitative; we do not directly engage in your negotiations or supervise the
            provision of services by users to their clients, nor are we party to any
            agreements you may enter into with other users.</p>
          <p>You are exclusively accountable for the content you publish on GigExecs
            and for your agreements with other users, including the vetting process
            and adherence to the terms of those agreements.</p>
        </li>
        <li>
          <b>Governing Law</b>
          <p>The laws of the United States of America, excluding its conflicts of law
            rules, shall govern these Terms and Your use of the Service. Your use of
            the Application or Website may also be subject to other local, state,
            national, or international laws.</p>
          <ol className={css.nestedList5}>
            <li>
              <b>For European Union (EU) Users</b>
              <p>If You are a European Union consumer, you will benefit from any
                mandatory provisions of the law of the country in which you are resident.</p>
            </li>
            <li>
              <b>United States Legal Compliance</b>
              <p>You represent and warrant that (i) You are not located in a country that is
                subject to the United States government embargo, or that has been
                designated by the United States government as a "terrorist supporting"
                country, and (ii) You are not listed on any United States government list of
                prohibited or restricted parties.</p>
            </li>
            <li>
              <b>Severability</b>
              <p>If any provision of these Terms is held to be unenforceable or invalid, such
                provision will be changed and interpreted to accomplish the objectives of
                such provision to the greatest extent possible under applicable law and
                the remaining provisions will continue in full force, and effect.</p>
            </li>
            <li>
              <b>Waiver</b>
              <p>Except as provided herein, the failure to exercise a right or to require the
                performance of an obligation under these Terms shall not affect a party's
                ability to exercise such right or require such performance at any time
                thereafter nor shall the waiver of a breach constitute a waiver of any
                subsequent breach.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>User Accounts</b>
          <ol className={css.nestedList6}>
            <li>
              <b>Account Creation</b>
              <p>When You create an account with Us, You must provide Us with
                information that is accurate, complete, and current at all times. Failure to
                do so constitutes a breach of the Terms, which may result in immediate
                termination of Your account on Our Service.</p>
              <p>You may not use as a username the name of another person or entity or
                that is not lawfully available for use, a name or trademark that is subject to
                any rights of another person or entity other than You without appropriate
                authorisation, or a name that is otherwise offensive, vulgar or obscene.</p>
            </li>
            <li>
              <b>Account Information</b>
              <p>You may be asked to supply certain information relevant to Your Account
                including, without limitation, Your name, Your email, Your phone number,
                and Your address.</p>
              <p>You may have to provide documents to comply with identity verification.</p>
              <p>Before or during posting services, you may be asked to supply, without
                limitation, payment details, and Your identity documents for proof
                verification.</p>
              <p>Before or during placing an Order, you may be asked to supply, without
                limitation, Your credit card number, the expiration date of Your credit card,
                Your billing address, and other relevant information.</p>
            </li>
            <li>
              <b>Account Review</b>
              <p>As part of our vetting process for new users in our platform and to protect
                the quality of our community, we perform basic checks to confirm the
                identity of the users, professional track record and the skills and expertise
                on offer. We do not accept however any responsibility for the reliability,
                accuracy, and completeness of any information provided by users.</p>
            </li>
            <li>
              <b>Account Password</b>
              <p>You are responsible for safeguarding the password that You use to access
                the Service and for any activities or actions under Your password, whether
                Your password is with Our Service or a Third-Party Social Media Service.</p>
              <p>You agree not to disclose Your password to any third party. You must
                notify Us immediately upon becoming aware of any breach of security or
                unauthorised use of Your account.</p>
            </li>
            <li>
              <b>Account Termination</b>
              <p>We may terminate or suspend Your Account immediately, without prior
                notice or liability, for any reason whatsoever, including without limitation if
                You breach these Terms of Service. Upon termination, Your right to use
                the Service will cease immediately.</p>
              <p>If You wish to terminate Your Account, You may simply discontinue using
                the Service or contact Us for help to delete your account.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>Content</b>
          <ol className={css.nestedList7}>
            <li>
              <b>Your Right to Post Content</b>
              <p>Our Service allows You to post Content. You are responsible for the
                Content that You post to the Service, including its legality, reliability, and
                appropriateness.</p>
              <p>By posting Content to the Service, You grant Us the right and license to
                use, modify, publicly perform, publicly display, reproduce, and distribute
                such Content on and through the Service. You retain any and all of Your
                rights to any Content You submit, post, or display on or through the
                Service and You are responsible for protecting those rights. You agree
                that this license includes the right for Us to make Your Content available
                to other users of the Service, who may also use Your Content subject to
                these Terms.</p>
              <p>You represent and warrant that: (i) the Content is Yours (You own it) or
                You have the right to use it and grant Us the rights and license as
                provided in these Terms, and (ii) the posting of Your Content on or through
                the Service does not violate the privacy rights, publicity rights, copyrights,
                contract rights or any other rights of any person.</p>
            </li>
            <li>
              <b>Content Restrictions</b>
              <p>The Company is not responsible for the content of the Service's users.
                You expressly understand and agree that You are solely responsible for
                the Content and for all activity that occurs under your account, whether
                done so by You or any third person using Your account.</p>
              <p>You may not transmit any Content that is unlawful, offensive, upsetting,
                intended to disgust, threatening, libellous, defamatory, obscene, or
                otherwise objectionable. Examples of such objectionable Content include,
                but are not limited to, the following:</p>
              <ul style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: 22 }}>
                <li>Unlawful or promoting unlawful activity.</li>
                <li>Defamatory, discriminatory, or mean-spirited content, including
                  references or commentary about religion, race, sexual orientation,
                  gender, national/ethnic origin, or other targeted groups.</li>
                <li>Spam, machine, or randomly–generated, constituting unauthorised or
                  unsolicited advertising, chain letters, any other form of unauthorised
                  solicitation, or any form of lottery or gambling.</li>
                <li>Containing or installing any viruses, worms, malware, trojan horses, or
                  other content that is designed or intended to disrupt, damage, or limit
                  the functioning of any software, hardware, or telecommunications
                  equipment or to damage or obtain unauthorised access to any data or
                  other information of a third person.</li>
                <li>Infringing on any proprietary rights of any party, including patent,
                  trademark, trade secret, copyright, right of publicity, or other rights.</li>
                <li>Impersonating any person or entity including the Company or Operator
                  and its employees or representatives.</li>
                <li>Violating the privacy of any third person.</li>
                <li>False information and features.</li>
              </ul>
              <p>The Company reserves the right, but not the obligation, to, in its sole
                discretion, determine whether or not any Content is appropriate and
                complies with these Terms, refuse or remove this Content. The Company
                further reserves the right to make formatting and edits and change the
                manner of any Content. The Company can also limit or revoke the use of
                the Service if You post such objectionable Content. As the Company
                cannot control all content posted by users and/or third parties on the
                Service, you agree to use the Service at your own risk. You understand
                that by using the Service You may be exposed to content that You may
                find offensive, indecent, incorrect, or objectionable, and You agree that
                under no circumstances will the Company be liable in any way for any
                content, including any errors or omissions in any content, or any loss or
                damage of any kind incurred as a result of your use of any content.</p>
            </li>
            <li>
              <b>Content Backups</b>
              <p>Although regular backups of Content are performed, the Company does
                not guarantee there will be no loss or corruption of data.</p>
              <p>Corrupt or invalid backup points may be caused by, without limitation,
                Content that is corrupted prior to being backed up or that changes during
                the time a backup is performed.</p>
              <p>The Company will provide support and attempt to troubleshoot any known
                or discovered issues that may affect the backups of Content. But You
                acknowledge that the Company has no liability related to the integrity of
                Content or the failure to successfully restore Content to a usable state.</p>
              <p>You agree to maintain a complete and accurate copy of any Content in a
                location independent of the Service.</p>
            </li>
            <li>
              <b>Intellectual Property of Others and Copyright Infringement</b>
              <p>We respect the intellectual property and copyrights of others. You may be
                held accountable for damages (including costs and attorneys' fees) for
                misrepresenting that any Content is infringing Your copyright. It is Our
                policy to respond to any claim that Content posted on the Service
                infringes a copyright or other intellectual property infringement of any
                person.</p>
              <p>We are ready to comply with local regulations in that matter.</p>
              <p>If You are a copyright owner or authorised on behalf of one, and You
                believe that the copyrighted work has been copied in a way that
                constitutes copyright infringement that is taking place through the Service,
                You must submit Your notice in writing to the attention of our copyright
                agent via email (see 2 - Contact Us) and include in Your notice the
                following information related to the alleged infringement:</p>
              <ul style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: 22 }}>
                <li>An electronic or physical signature of the person authorised to act on
                  behalf of the owner of the copyright's interest.</li>
                <li>A description of the copyrighted work that You claim has been infringed,
                  including the URL (i.e., web page address) of the location where the
                  copyrighted work exists or a copy of the copyrighted work.</li>
                <li>Identification of the URL or other specific location on the Service where
                  the material that You claim is infringing is located.</li>
                <li>Your address, telephone number, and email address.</li>
                <li>A statement by You that You have a good faith belief that the disputed
                  use is not authorised by the copyright owner, its agent, or the law.</li>
                <li>A statement by You, made under penalty of perjury, that the above
                  information in Your notice is accurate and that You are the copyright
                  owner or authorised to act on the copyright owner's behalf.</li>
              </ul>
              <p>Upon receipt of a notification, the Company will take whatever action, in
                its sole discretion, it deems appropriate, including removal of the
                challenged content from the Service.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>Orders of Services</b>
          <p>By placing an Order for Services through the platform, You warrant that
            You are legally capable of entering into binding contracts.</p>
          <ol className={css.nestedList8}>
            <li>
              <b>Position of GigExecs in Services</b>
              <p>The role of GigExecs.com is strictly of a facilitator between users of our
                platform, more specifically “Professionals and “Clients”. We are, therefore,
                not a party to any agreement between both, which limits Our liabilities in
                any disputes between them.</p>
            </li>
            <li>
              <b>Your Information as Buyer</b>
              <p>If You wish to place an Order for services, You may be asked to supply
                certain information relevant to Your Order including, without limitation,
                Your name, Your email, Your phone number, Your credit card number, the
                expiration date of Your credit card, Your billing address.</p>
              <p>You represent and warrant that: (i) You have the legal right to use any
                credit or debit card(s) or other payment method(s) in connection with any
                Order; and that (ii) the information You supply to us is true, correct, and
                complete.</p>
              <p>By submitting such information, You grant us the right to provide the
                information to payment processing third parties for purposes of facilitating
                the completion of Your Order.</p>
            </li>
            <li>
              <b>Availability, Errors, and Inaccuracies</b>
              <p>We and our platform Sellers are constantly updating the offerings of
                Services. The Services available may be mispriced, described
                inaccurately, or unavailable, and Sellers and We may experience delays
                in updating information regarding the Services in Our advertising on other
                websites.</p>
              <p>We and Sellers cannot and do not guarantee the accuracy or
                completeness of any information, including prices, product images,
                specifications, availability, and services. We reserve the right to change or
                update information and to correct errors, inaccuracies, or omissions at any
                time without prior notice.</p>
            </li>
            <li>
              <b>Prices Policy</b>
              <p>The Company reserves the right to revise their prices or fees at any time.
                For updated information about pricing please consult our website {" "}
                <a href="www.gigexecs.com" target="_blank">www.gigexecs.com</a>.</p>
            </li>
            <li>
              <b>Payments</b>
              <p>Payments in our Platform can be made through Stripe (<a href="www.stripe.com" target="_blank">www.stripe.com</a>),
                we rely on in this payment gateway that have their own terms of service
                and their own limitations. We may add in the future other payment
                gateways (e.g PayPal) to the platform.</p>
              <p>Payment cards (credit cards or debit cards) are subject to validation
                checks and authorisation by Your card issuer. If we do not receive the
                required authorisation, We will not be liable for any delay or non-delivery
                of Your Order.</p>
            </li>
            <li>
              <b>Service Fees</b>
              <p>We may charge You fees (and applicable Taxes) for the right to use the
                Service. More information about when service fees apply and how they
                are calculated are available in our website <a href="www.gigexecs.com" target="_blank">www.gigexecs.com</a>; We
                reserve the right to change the service fees at any time.</p>
            </li>
            <li>
              <b>Order Modification</b>
              <p>You as a user of our service, are responsible for any Order modifications
                you agree to make via the Service and agree to pay any additional
                amounts, fees, or taxes associated with any Order modification. Our role
                is purely of a facilitator between “Clients” and “Professionals”.</p>
            </li>
            <li>
              <b>Order Cancellation</b>
              <ol className={css.nestedListInsideList8}>
                <li>
                  <b>Our Order Cancellation Rights</b>
                  <p>We reserve the right to refuse or cancel Your Order at any time for certain
                    reasons including but not limited to:</p>
                  <ul style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: 22 }}>
                    <li>Service availability</li>
                    <li>Errors in the description or prices for Services</li>
                    <li>Errors in Your Order</li>
                  </ul>
                  <p>We reserve the right to refuse or cancel Your Order if fraud or an
                    unauthorised or illegal transaction or trade is suspected.</p>
                </li>
                <li>
                  <b>Order Cancellation by Buyers</b>
                  <p>If You as a Buyer cancel an Order, the amount You paid (including the
                    Service fees) is not refunded.</p>
                  <p>If something outside Your control requires You to cancel an Order, or if
                    You think your Order should be refunded, contact Us on <a href="mailto:help@gigexecs.com">help@gigexecs.com</a>.</p>
                </li>
                <li>
                  <b>Order Cancellation by Sellers</b>
                  <p>If You as a Seller cancel an Order before any service has been provided,
                    the amount the Buyer paid (including the Service fees) will be refunded to
                    the Buyer and will not be transferred to the Seller. We reserve the right to
                    charge cancellation fees, for more information about fees consult our
                    website on <a href="www.gigexecs.com" target="_blank">www.gigexecs.com</a>.</p>
                  <p>If something outside Your control requires You to cancel an Order, or if
                    You think your Order should be refunded, contact Us on <a href="mailto:help@gigexecs.com">help@gigexecs.com</a>.</p>
                </li>
              </ol>
            </li>
            <li>
              <b>Order Dispute</b>
              <p>If a Buyer or a Seller disputes an Order, the Company should be notified
                by email via <a href="mailto:help@gigexecs.com">help@gigexecs.com</a>. Buyer and Seller should pursue all
                options to settle the dispute in an amicable matter. If not possible, the
                dispute will be resolved at Our sole discretion and based on our own
                independent interpretation of the facts.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>Disclaimer of Warranties and Limitation of Liability</b>
          <ol className={css.nestedList9}>
            <li>
              <b>Limitation of Liability</b>
              <p>Notwithstanding any damages that You might incur, the entire liability of
                the Company and any of its suppliers under any provision of this Terms
                and Your exclusive remedy for all of the foregoing shall be limited to the
                amount actually paid by You through the Service.</p>
              <p>To the maximum extent permitted by applicable law, in no event shall the
                Company or Operator or its suppliers be liable for any special, incidental,
                indirect, or consequential damages whatsoever (including, but not limited
                to, damages for loss of profits, loss of data or other information, for
                business interruption, for personal injury, loss of privacy arising out of or in
                any way related to the use of or inability to use the Service, third-party
                software and/or third-party hardware used with the Service, or otherwise
                in connection with any provision of this Terms), even if the Company or
                Operator or any supplier has been advised of the possibility of such
                damages and even if the remedy fails of its essential purpose.</p>
              <p>Some jurisdictions do not allow the exclusion of implied warranties or
                limitation of liability for incidental or consequential damages, which means
                that some of the above limitations may not apply. In these jurisdictions,
                each party's liability will be limited to the greatest extent permitted by law.</p>
            </li>
            <li>
              <b>"AS IS" and "AS AVAILABLE" Disclaimer</b>
              <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all
                faults and defects without warranty of any kind. To the maximum extent
                permitted under applicable law, the Company, on its own behalf and on
                behalf of its Affiliates and its and their respective licensors and service
                providers, expressly disclaims all warranties, whether express, implied,
                statutory, or otherwise, with respect to the Service, including all implied
                warranties. Without limitation to the foregoing, the Company provides no
                warranty or undertaking, and makes no representation of any kind that the
                Service will meet Your requirements, achieve any intended results, be
                compatible or work with any other software, applications, systems, or
                services, operate without interruption, meet any performance or reliability
                standards or be error-free or that any errors or defects can or will be
                corrected.</p>
              <p>Without limiting the foregoing, neither the Company nor any of the
                company's providers makes any representation or warranty of any kind,
                express or implied: (i) as to the operation or availability of the Service, or
                the information, content, and products included thereon; (ii) that the
                Service will be uninterrupted or error-free; (iii) as to the accuracy,
                reliability, or currency of any information or content provided through the
                Service; or (iv) that the Service, its servers, the content, or e-mails sent
                from or on behalf of the Company or Operator are free of viruses, scripts,
                trojan horses, worms, malware, timebombs or other harmful components.</p>
              <p>Some jurisdictions do not allow the exclusion of certain types of
                warranties or limitations on applicable statutory rights of a consumer, so
                some or all of the above exclusions and limitations may not apply to You.
                But in such a case the exclusions and limitations set forth in this section
                shall be applied to the greatest extent enforceable under applicable law.</p>
            </li>
            <li>
              <b>Links to Other Websites</b>
              <p>Our Service may contain links to third-party websites or services that are
                not owned or controlled by the Company.</p>
              <p>The Company has no control over and assumes no responsibility for, the
                content, privacy policies, or practices of any third-party websites or
                services. You further acknowledge and agree that the Company shall not
                be responsible or liable, directly or indirectly, for any damage or loss
                caused or alleged to be caused by or in connection with the use of or
                reliance on any such content, goods, or services available on or through
                any such web sites or services.</p>
              <p>We strongly advise You to read the terms of service and privacy policies
                of any third-party websites or services that You visit.</p>
            </li>
            <li>
              <b>Translation Interpretation</b>
              <p>These Terms of Service may have been translated if We have made them
                available to You on our Service. You agree that the original English text
                shall prevail in the case of a dispute.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>Disputes Resolution about the Service</b>
          <p>If You have any concerns or disputes about the Service, You agree to first
            try to resolve the dispute informally by contacting the Company.</p>
        </li>
        <li>
          <b>Intellectual Property of the Service</b>
          <p>The Service and its original content (excluding Content provided by You or
            other users), features, and functionality are and will remain the exclusive
            property of the Company or Operator and its licensors.</p>
          <p>The Service is protected by copyright, trademark, and other laws of both
            the Country and foreign countries.</p>
          <p>Our trademarks and trade dress may not be used in connection with any
            product or service without the prior written consent of the Company.</p>
        </li>
        <li>
          <b>Your feedback to Us</b>
          <p>You assign all rights, title, and interest in any Feedback You provide the
            Company. If for any reason such assignment is ineffective, You agree to
            grant the Company a non-exclusive, perpetual, irrevocable, royalty-free,
            worldwide right and license to use, reproduce, disclose, sub-license,
            distribute, modify and exploit such Feedback without restriction.</p>
        </li>
        <li>
          <b>Changes to these Terms of Service</b>
          <p>We reserve the right, at Our sole discretion, to modify or replace these
            Terms at any time. If a revision is material We will make reasonable efforts
            to provide at least 30 days notice prior to any new terms taking effect.
            What constitutes a material change will be determined at Our sole
            discretion.</p>
          <p>By continuing to access or use Our Service after those revisions become
            effective, You agree to be bound by the revised terms. If You do not agree
            to the new terms, in whole or in part, please stop using the Application or
            Website and the Service.</p>
        </li>
      </ol>
    </div >
    // <SectionBuilder
    //   {...sectionsData}
    //   options={{
    //     fieldComponents: {
    //       heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
    //     },
    //     isInsideContainer: true,
    //   }}
    // />
  );
};

// Presentational component for TermsOfServicePage
const TermsOfServicePageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage />}
    />
  );
};

TermsOfServicePageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TermsOfServicePage = compose(connect(mapStateToProps))(TermsOfServicePageComponent);

const TOS_ASSET_NAME = ASSET_NAME;
export { TOS_ASSET_NAME, TermsOfServicePageComponent, TermsOfServiceContent };

export default TermsOfServicePage;
